import CustomerOverviewList from "./list";
import CustomerOverviewShow from "./show";


if (document.body.getAttribute("data-current-path") === "customer/customers") {
    const action = document.body.getAttribute("data-current-action")
    if (action === "index") {
        new CustomerOverviewList();
    } else if(action === "show") {
        new CustomerOverviewShow();
    }
}